<template>
  <div class="meeting-detail-page common-page">
    <Nav />
    <div class="top">
      <video :src="detail.url" alt="" controls/>
    </div>
    <div class="common-card-wra card-wrap">
      <h2>{{ detail.name }}</h2>
      <p class="text" v-html="detail.detail"></p>
      <div class="btn-wrap">
        <van-button round block type="info" native-type="submit" @click="openIframeForm(detail)"
          >提交</van-button
        >
      </div>
    </div>
    <van-popup
      v-model="iframeLinkShow"
      :close-on-click-overlay="false"
      :closeable="true"
    >
      <div class="pop-form">
        <div class="iframe-box">
          <div v-if="iframeLinkShow" class="laoding-box">
            <van-loading color="#fc0">加载中...</van-loading>
          </div>
          <iframe
            :src="iframeLink"
            @load="onLoad"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Nav from "../../component/nav.vue";
import { Button, Popup, Loading } from "vant";
import request from '../../utils/request';
export default {
  name: "MeeetingDetail",
  components: {
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
    Nav
  },
  data() {
    return {
      isLoading: true,
      iframeLinkShow: false,
      iframeLink: "",
      detail: {}
    };
  },
  methods: {
    // 获取新闻列表数据
    fetchInfo(id) {
      request.get('/meeting/'+id) // 假设接口地址为 /news
        .then(response => {
          this.detail = response; // 将接口返回的数据赋值给newsList
          document.title = this.detail.name;
        })
        .catch(error => {
          console.error('Error fetching news:', error);
        });
    },
    onLoad() {
      this.isLoading = false;
    },
    // 跳转到在线会议
    openIframeForm(item) {
        // this.iframeLinkShow = true;
        this.iframeLink = item.form_url;
        window.location.href = this.iframeLink
    },
  },
  created() {
    const id = this.$route.query.id;
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchInfo(id);
  }
};
</script>
<style lang="less" scoped>
.meeting-detail-page {
  .top {
    padding-top: 80px;
    video {
      display: block;
      width: 100%;
      height: 211px;
      object-fit: cover;
    }
  }
  .card-wrap {
    padding: 40px 32px 0 32px;
    box-sizing: border-box;
    min-height: calc(100vh - 211px);
    border-radius: 40px 40px 0px 0px;
    border-top: 2px solid #fff;
    background: linear-gradient(180deg, #f0f5f7 0%, #dfe4e5 87.52%);
    h2 {
      margin-bottom: 8px;
      color: #212027;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 33.6px */
    }
    .text {
      color: #505054;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.28px;
    }
  }
  .btn-wrap {
    position: absolute;
    bottom: 100px;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    .van-button--info {
      background: #fff;
      color: #000;
      border: none;
    }
  }
}
</style>
